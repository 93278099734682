import React, { useEffect, useState } from "react";
import $ from "jquery";
import { useStore } from "../../store";
import Image from '../Utils/image';

// == Import
import "./styles.scss";

// == Composant
const GardenClosed = () => {

   const [remainingTime, setRemainingTime] = useState();

   const calculateTime = () => {
    const current = new Date();
    const next = new Date(current.getYear(), current.getMonth(), current.getDate(), 18);
    // const next = new Date(2021, 3, 17, 19, 0);
    const difference = new Date(next.getTime() - current.getTime());
    const remain = `${difference.getHours() > 9 ? difference.getHours():`0${difference.getHours()}`}:${difference.getMinutes() > 9 ? difference.getMinutes():`0${difference.getMinutes()}`}:${difference.getSeconds() > 9 ? difference.getSeconds():`0${difference.getSeconds()}`}`
    setRemainingTime(remain);
    if(remain === '00:00:01') setTimeout(() => location.reload(), 500);
   }

   useEffect(() => {
    setInterval(() => calculateTime(), 1000);
   }, [])
  
  return (
   <div className="closed">
       <div className="closed__plants"><Image id="133d07a5-5d13-46dd-b644-7e92436fea5e" /></div>
       <h1 className="closed__title">The Garden of Tangled Data</h1>
       <p className="closed__text">
       is open every day from 7pm to 11pm (GMT+1), please come back in
       </p>
       <span>{remainingTime}</span>
   </div>
  );
};

// == Export
export default GardenClosed;
