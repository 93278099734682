import styled from "styled-components";
import Sketch from "react-p5";

const Container = styled.div`
  text-align: left;
  width: ${(props) => props.width + "px"};
  height: ${(props) => props.height + "px"};
  /* background-color: lightblue; */
`;

export default Container;
