import { extend } from "react-three-fiber";
import { shaderMaterial } from "@react-three/drei";
import { vertexShader } from "./helpers";

const Material = shaderMaterial(
  {
    iFrame: { value: 0 },
    iResolution: { value: null },
    iMouse: { value: null },
    tex: undefined,
    hovered: false,
    mouseX: 0.0,
    mouseY: 0.0,
  },
  vertexShader,
  `
  varying vec2 vUv;
  uniform int iFrame;
  uniform vec3 iResolution;
  uniform vec4 iMouse;
  uniform sampler2D tex;
  uniform bool hovered;
  uniform float mouseX;
  uniform float mouseY;

  void main() {
    vec2 uv = vUv;
    vec2 distortedPosition = vec2(uv.x, uv.y+uv.x*0.01);
    ivec2 textureSize2d = textureSize(tex,0);
      // distortedPosition = vec2(uv.x+ uv.y*sin(iFrame), uv.y + uv.x*sin(iFrame));
    // vec4 _texture = texture2D(tex, distortedPosition);
    vec4 _texture = texture2D(tex, uv);//distortedPosition);
    // gl_FragColor = vec4(1.0, 0.0, 1.0, 1.0);
    // gl_FragColor = _texture;

    vec4 color = _texture;
    // if(_texture.y > (1.0 - iFrame) * 1.0){
    //     color = vec4(_texture.x, 1.0, _texture.z, 1.0);
    // }else{
    //     color = vec4(_texture.x, _texture.y, _texture.z, 1.0);
    // }
    // if(uv.x* float(textureSize2d.x) > mouseX){
    //   // color = vec4(1.0,0.0,0.0,1.0);
    //   color = color + vec4(0.8, 0.1, 0.5, 1.0);
    // }

    gl_FragColor = color;

    if(gl_FragCoord.x == 0.0){
      gl_FragColor = vec4(1.0,0.0, 0.0, 1.0);
    }

    if(gl_FragCoord.x > iResolution.x / 2.0){
      color = vec4(
        mix(
          color.rgb, vec4(1.0,0.0, 0.0, 1.0).rgb, 0.3
          ), 
          1.0
      );
    }

    if(gl_FragCoord.y > iResolution.y / 2.0){
      color = vec4(
        mix(
          color.rgb, vec4(1.0,1.0, 0.0, 1.0).rgb, 0.3
          ), 
          1.0
      );
    }
    gl_FragColor = color;
  }
  `
);

extend({ Material });
