import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getSeeds, getAssetUrl } from "../api";

const Container = styled.div`
  width: 80%;
  height: 300px;
`;
const Image = styled.img`
  width: 33%;
  border: ${(props) => (props.picked ? "3px solid pink" : "none")};
`;

export default function SeedPicker({ pickedSeed, onSeedPicked }) {
  const [seeds, setSeeds] = useState([]);
  //   const userId = useStore((state) => state.userId);

  //   fetches all seeds
  //   gets called only once
  useEffect(() => {
    getSeeds().then((data) => {
      if (data && data.length > 0) {
        const seeds = [
          data[Math.floor(Math.random() * data.length)],
          data[Math.floor(Math.random() * data.length)],
          data[Math.floor(Math.random() * data.length)],
        ];
        setSeeds(seeds);
      }
    });
  }, [setSeeds]);

  return (
    <>
      <Container>
        {seeds.map((seed, index) => {
          return (
            <Image
              key={index}
              src={getAssetUrl(seed.id, 400, 400)}
              onClick={() => {
                if (!pickedSeed) {
                  onSeedPicked(seed);
                }
              }}
              picked={pickedSeed && pickedSeed.id === seed.id}
            ></Image>
          );
          //   <div key={index}>{seed.id}</div>;
        })}
        {pickedSeed && pickedSeed.id}
      </Container>
    </>
  );
}
