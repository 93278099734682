import React, { useEffect, useState, useReducer } from "react";
import {
  useWindowSize,
  useWindowWidth,
  useWindowHeight,
} from "@react-hook/window-size";
import styled from "styled-components";
import { uploadSeed, getAssetUrl } from "../api";
import { growthStages } from "../constants";
import { useStore } from "../store";
// import Saen from "./effects/Sandbox/StainEffect";
// import Keimen from "./effects/Sandbox/Welken";
// import Verzweigen from "./effects/Sandbox/Verzweigen";
// import Bluehen from "./effects/Sandbox/Phylotaxis";
// import Kreuzen from "./effects/Kreuzen";
// import Welken from "./effects/Sandbox/NewWelken";

import Saen from "./effects/Sandbox/StainEffect";
import Keimen from "./effects/Sandbox/Verzweigen";
import Verzweigen from "./effects/Sandbox/NewWelken";
import Bluehen from "./effects/Sandbox/Phylotaxis";
import Kreuzen from "./effects/Kreuzen";
import Welken from "./effects/Sandbox/Welken";

const Container = styled.div`
  /* width: 100%; */
`;

export default function Seed({ growthStage, debug }) {
  const userId = useStore((state) => state.userId);
  const seed = useStore((state) => state.seed);
  const selectedSeed = useStore((state) => state.selectedSeed);
  const saen = useStore((state) => state.saen);
  const keimen = useStore((state) => state.keimen);
  const verzweigen = useStore((state) => state.verzweigen);
  const bluehen = useStore((state) => state.bluehen);
  const kreuzen = useStore((state) => state.kreuzen);
  const welken = useStore((state) => state.welken);
  const currentGrowthStage = useStore((state) => state.growthStage);

  const saenActive = useStore((state) => state.saenActive);
  const keimenActive = useStore((state) => state.keimenActive);
  const verzweigenActive = useStore((state) => state.verzweigenActive);
  const bluehenActive = useStore((state) => state.bluehenActive);
  const kreuzenActive = useStore((state) => state.kreuzenActive);
  const welkenActive = useStore((state) => state.welkenActive);

  const setSaenActive = useStore((state) => state.setSaenActive);
  const setKeimenActive = useStore((state) => state.setKeimenActive);
  const setVerzweigenActive = useStore((state) => state.setVerzweigenActive);
  const setBluehenActive = useStore((state) => state.setBluehenActive);
  const setKreuzenActive = useStore((state) => state.setKreuzenActive);
  const setWelkenActive = useStore((state) => state.setWelkenActive);
  // const subscription = useStore.subscribe((value) =>
  //   console.log("Seed store changed", value)
  // );

  // useEffect(() => {
  //   setWidth(0.8 * window.innerHeight);
  //   setHeight(0.8 * window.innerHeight);
  // }, []);
  const windowHeight = useWindowHeight();
  const width = Math.floor(0.8 * windowHeight);
  const height = Math.floor(0.8 * windowHeight);

  // const sent = [false, false, false, false, false, false, false, false];

  return (
    <>
      {/* show debug info */}
      {debug && (
        <>
          selectedGrowthStage: {growthStage}, current growth stage:{" "}
          {currentGrowthStage}
          <br></br>
          {seed && <span>seed</span>}
          <br></br>
          {saen && <span>saen</span>}
          <br></br>
          {keimen && <span>keimen</span>}
        </>
      )}
      <Container className="p5Seed">
        <>
          {/* saen */}
          {!saen &&
            seed &&
            growthStage === growthStages.saen &&
            currentGrowthStage === growthStages.saen && (
              <Saen
                active={saenActive}
                src={getAssetUrl(seed.id, width, height)}
                width={width}
                height={height}
                runtime={5000}
                onClick={() => {
                  //   setSaenActive(true);
                }}
                onFinished={(data) => {
                  if (saenActive) {
                    uploadSeed(data, userId, currentGrowthStage); //data, userId, growthStage
                    setSaenActive(false);
                  }
                }}
              ></Saen>
            )}
          {/* keimen */}
          {/* based on saen, seed as fallback */}
          {!keimen &&
            seed &&
            growthStage === growthStages.keimen &&
            currentGrowthStage === growthStages.keimen && (
              <Keimen
                active={keimenActive}
                src={getAssetUrl(saen ? saen.id : seed.id, width, height)}
                width={width}
                height={height}
                onClick={() => {
                  // setKeimenActive(true);
                }}
                onFinished={(data) => {
                  if (keimenActive) {
                    uploadSeed(data, userId, growthStages.keimen); //data, userId, growthStage
                    setKeimenActive(false);
                  }
                }}
              ></Keimen>
            )}
          {/* verzweigen */}
          {/* based on keimen, seed as fallback */}
          {!verzweigen &&
            seed &&
            growthStage === growthStages.verzweigen &&
            currentGrowthStage === growthStages.verzweigen && (
              <Verzweigen
                active={verzweigenActive}
                src={getAssetUrl(keimen ? keimen.id : seed.id, width, height)}
                width={width}
                height={height}
                onClick={() => {
                  // setVerzweigenActive(true);
                }}
                onFinished={(data) => {
                  if (verzweigenActive) {
                    uploadSeed(data, userId, growthStages.verzweigen); //data, userId, growthStage
                    setVerzweigenActive(false);
                  }
                }}
              ></Verzweigen>
            )}
          {/* bluehen */}
          {/* based on verzweigen, seed as fallback */}
          {!bluehen &&
            seed &&
            growthStage === growthStages.bluehen &&
            currentGrowthStage === growthStages.bluehen && (
              <Bluehen
                active={bluehenActive}
                src={getAssetUrl(saen ? saen.id : seed.id, width, height)}
                width={width}
                height={height}
                onClick={() => {
                  // setBluehenActive(true);
                }}
                onFinished={(data) => {
                  if (bluehenActive) {
                    uploadSeed(data, userId, growthStages.bluehen); //data, userId, growthStage
                    setBluehenActive(false);
                  }
                }}
              ></Bluehen>
            )}
          {/* kreuzen */}
          {/* based on bluehen, seed as fallback */}
          {/* based on verzweigen, seed as fallback */}
          {!kreuzen &&
            seed &&
            selectedSeed &&
            growthStage === growthStages.kreuzen &&
            currentGrowthStage === growthStages.kreuzen && (
              // <>
              //   {typeof selectedSeed === "object"
              //     ? selectedSeed.id
              //     : selectedSeed}
              // </>
              <Kreuzen
                active={kreuzenActive}
                // src={getAssetUrl(bluehen ? bluehen.id : seed.id, width, height)}
                src={getAssetUrl(keimen ? keimen.id : seed.id, width, height)}
                src2={getAssetUrl(
                  typeof selectedSeed === "object"
                    ? selectedSeed.id
                    : selectedSeed
                )}
                width={width}
                height={height}
                onClick={() => {
                  // setKreuzenActive(true);
                }}
                onFinished={(data) => {
                  if (kreuzenActive) {
                    uploadSeed(data, userId, growthStages.kreuzen); //data, userId, growthStage
                    setKreuzenActive(false);
                  }
                }}
              ></Kreuzen>
            )}
          {/* welken */}
          {/* based on kreuzen, seed as fallback */}
          {!welken &&
            seed &&
            growthStage === growthStages.welken &&
            currentGrowthStage === growthStages.welken && (
              <Welken
                active={welkenActive}
                src={getAssetUrl(kreuzen ? kreuzen.id : seed.id, width, height)}
                width={width}
                height={height}
                onClick={() => {
                  // setWelkenActive(true);
                }}
                onFinished={(data) => {
                  if (welkenActive) {
                    uploadSeed(data, userId, growthStages.welken); //data, userId, growthStage
                    setWelkenActive(false);
                  }
                }}
              ></Welken>
            )}
        </>

        {/* display image if already uploaded */}
        <>
          {saen && growthStage === growthStages.saen && (
            <img src={getAssetUrl(saen.id, width, height)}></img>
          )}
          {keimen && growthStage === growthStages.keimen && (
            <img src={getAssetUrl(keimen.id, width, height)}></img>
          )}
          {verzweigen && growthStage === growthStages.verzweigen && (
            <img src={getAssetUrl(verzweigen.id, width, height)}></img>
          )}
          {bluehen && growthStage === growthStages.bluehen && (
            <img src={getAssetUrl(bluehen.id, width, height)}></img>
          )}
          {kreuzen && growthStage === growthStages.kreuzen && (
            <img src={getAssetUrl(kreuzen.id, width, height)}></img>
          )}
          {welken && growthStage === growthStages.welken && (
            <img src={getAssetUrl(welken.id, width, height)}></img>
          )}
        </>
      </Container>
    </>
  );
}
