const growthStages = {
  none: -1,
  initial: 0,
  saen: 1,
  keimen: 2,
  verzweigen: 3,
  bluehen: 4,
  kreuzen: 5,
  welken: 6,
};

export { growthStages };
