import React from "react";
import styled from "styled-components";
import config from "../config.json";
import { useStore } from "../store";

const Container = styled.div`
  width: 80vw;
  height: 80vh;
  margin: auto;
  iframe {
    width: 100%;
    height: 100%;
  }
`;

export default function HyperCam({ url }) {
  const active = useStore((state) => state.hyperCam);
  return (
    <Container>
      {active && <iframe src={url} allow="camera; microphone"></iframe>}
    </Container>
  );
}
