import React, { useEffect, useState } from "react";
import config from "../../config.json";

// == Import
import "./styles.scss";

// == Composant
const People = () => {
  const [connections, setConnections] = useState(0);
  useEffect(() => {
    const client = new WebSocket(config.wss);

    client.onmessage = (event) => {
      try {
        const action = JSON.parse(event.data);
        // console.log(action, event.data);
        if (action.type === "CONNECTIONEVENT") {
          // console.log(setConnections);
          setConnections(action.payload.value);
        }
      } catch (error) {}
    };
    let intervarlId = -1;
    client.onopen = () => {
      intervarlId = setInterval(() => {
        const message = {
          type: "PING",
        };
        client.send(JSON.stringify(message));
      }, 10000);
    };
    client.onclose = () => {
      console.log("connection closed");
      clearInterval(intervarlId);
    };
    return () => {
      client.close();
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div
      className="people"
      data-22700="opacity:0"
      data-23000="opacity:1"
      data-28000="opacity:1"
      data-28200="opacity:0"
      data-36700="opacity:0"
      data-37000="opacity:1"
      data-44000="opacity:1"
      data-44200="opacity:0"
      data-67700="opacity:0"
      data-68000="opacity:1"
      data-70000="opacity:1"
      data-70200="opacity:0"
      data-107700="opacity:0"
      data-108000="opacity:1"
      data-113000="opacity:1"
      data-113200="opacity:0"
      data-124700="opacity:0"
      data-125000="opacity:1"
      data-129000="opacity:1"
      data-129200="opacity:0"
      data-134700="opacity:0"
      data-135000="opacity:1"
      data-140000="opacity:1"
      data-140200="opacity:0"
      data-150700="opacity:0"
      data-151000="opacity:1"
      data-153000="opacity:1"
      data-153200="opacity:0"
    >
      {connections} entities are strolling with you through the Garden right
      now.
    </div>
  );
};

// == Export
export default People;
