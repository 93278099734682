import React from 'react';
import lazySizes from 'lazysizes';
import { getAssetUrl } from '../../api';
import './image.css';

const resize = (id, option) => {
    return getAssetUrl(id, option);
}

const Image = ({ id }) => {
    let w640 = `${(resize(id, '640'))} 640w`
    let w768 = (resize(id, '768'))+" 768w"

    return (

        <img 
            src={resize(id, '150')}
            data-sizes="auto"
            data-expand="1000"
            data-srcset={[w640,w768].join(', ')}
            className={`${["lazyload"].join(' ')}`}
        />

    )
}

export default Image


