import React, { useEffect, useState } from "react";
import styled from "styled-components";
import QRCode from "react-qr-code";
import { useStore } from "../store";
import config from "../config.json";
import { growthStages } from "../constants";
import Seed from "./Seed";
import { getAssetUrl, getSeedsByUser, getRandomSeed } from "../api";

import Button from "@material-ui/core/Button";
const updateInterval = 10000;
const Container = styled.div`
  width: 100vw;
  background-color: lightseagreen;
  color: black;
`;
const SectionContainer = styled.section`
  margin: 10%;
  background-color: lightsalmon;
`;
const Img = styled.img`
  width: 100px;
  height: auto;
`;
const Image = ({ seed, growthStage }) => {
  // console.log(seed);
  return (
    <>
      {seed && (
        <div>
          <h3>
            growthStage: {growthStage}, id: {seed.id}
          </h3>
          <Img src={getAssetUrl(seed.id, 100)}></Img>
        </div>
      )}
    </>
  );
};
export default function () {
  const userId = useStore((state) => state.userId);

  const seed = useStore((state) => state.seed);
  const selectedSeed = useStore((state) => state.selectedSeed);
  const saen = useStore((state) => state.saen);
  const keimen = useStore((state) => state.keimen);
  const verzweigen = useStore((state) => state.verzweigen);
  const bluehen = useStore((state) => state.bluehen);
  const kreuzen = useStore((state) => state.kreuzen);
  const welken = useStore((state) => state.welken);

  const setSeed = useStore((state) => state.setSeed);
  const setSelectedSeed = useStore((state) => state.setSelectedSeed);
  const setSaen = useStore((state) => state.setSaen);
  const setKeimen = useStore((state) => state.setKeimen);
  const setVerzweigen = useStore((state) => state.setVerzweigen);
  const setBluehen = useStore((state) => state.setBluehen);
  const setKreuzen = useStore((state) => state.setKreuzen);
  const setWelken = useStore((state) => state.setWelken);

  const setSaenActive = useStore((state) => state.setSaenActive);
  const setKeimenActive = useStore((state) => state.setKeimenActive);
  const setVerzweigenActive = useStore((state) => state.setVerzweigenActive);
  const setBluehenActive = useStore((state) => state.setBluehenActive);
  const setKreuzenActive = useStore((state) => state.setKreuzenActive);
  const setWelkenActive = useStore((state) => state.setWelkenActive);

  const setGrowthStage = useStore((state) => state.setGrowthStage);
  // const subscription = useStore.subscribe((value) =>
  //   console.log("effect debugger store changed", value)
  // );

  //   useEffect(() => {
  //     getUserId().then((data) => {
  //       setUserId(data);
  //     });
  //   }, []);

  useEffect(() => {
    const id = setInterval(() => {
      //   console.log(seed, saen, keimen, verzweigen, bluehen, kreuzen, welken);
      if (!selectedSeed) {
        getRandomSeed().then((response) => {
          if (response) {
            setSelectedSeed(response);
          }
        });
      }

      getSeedsByUser(userId).then((response) => {
        if (!response) {
          // console.log("invalid response", response);
          return;
        }
        // console.log("getting seeds", response);
        if (!seed && response[growthStages.initial]) {
          // console.log("set seed");
          setSeed(response[growthStages.initial]);
        }
        if (!saen && response[growthStages.saen]) {
          // console.log("set saen");
          setSaen(response[growthStages.saen]);
        }
        if (!keimen && response[growthStages.keimen]) {
          // console.log("set keimen");
          setKeimen(response[growthStages.keimen]);
        }
        if (!verzweigen && response[growthStages.verzweigen]) {
          // console.log("set verzweigen");
          setVerzweigen(response[growthStages.verzweigen]);
        }
        if (!bluehen && response[growthStages.bluehen]) {
          // console.log("set bluehen");
          setBluehen(response[growthStages.bluehen]);
        }
        if (!kreuzen && response[growthStages.kreuzen]) {
          // console.log("set kruezen");
          setKreuzen(response[growthStages.kreuzen]);
        }
        if (!welken && response[growthStages.welken]) {
          // console.log("set welken");
          setWelken(response[growthStages.welken]);
        }
      });
    }, updateInterval);
    return () => {
      clearInterval(id);
    };
  }, [
    userId,
    seed,
    selectedSeed,
    saen,
    keimen,
    verzweigen,
    bluehen,
    kreuzen,
    welken,
  ]);

  const Section = ({ growthStage, children }) => {
    return (
      <SectionContainer>
        {growthStage && (
          <>
            <h2>growthStage: {growthStage}</h2>

            <Button
              color="primary"
              variant="outlined"
              onClick={() => {
                setGrowthStage(growthStage);
              }}
            >
              show
            </Button>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => {
                if (growthStage === growthStages.saen) {
                  setSaenActive(true);
                } else if (growthStage === growthStages.keimen) {
                  setKeimenActive(true);
                } else if (growthStage === growthStages.verzweigen) {
                  setVerzweigenActive(true);
                } else if (growthStage === growthStages.bluehen) {
                  setBluehenActive(true);
                } else if (growthStage === growthStages.kreuzen) {
                  setKreuzenActive(true);
                } else if (growthStage === growthStages.welken) {
                  setWelkenActive(true);
                }
                // setGrowthStage(growthStage);
              }}
            >
              trigger
            </Button>
          </>
        )}
        {children}
      </SectionContainer>
    );
  };

  return (
    <Container>
      <Section>userId: {userId}</Section>
      <Section>
        <h2>stages</h2>
        <Image seed={seed} growthStage={growthStages.initial}></Image>
        <Image seed={saen} growthStage={growthStages.saen}></Image>
        <Image seed={keimen} growthStage={growthStages.keimen}></Image>
        <Image seed={verzweigen} growthStage={growthStages.verzweigen}></Image>
        <Image seed={bluehen} growthStage={growthStages.bluehen}></Image>
        <Image seed={kreuzen} growthStage={growthStages.kreuzen}></Image>
        <Image seed={welken} growthStage={growthStages.welken}></Image>
      </Section>
      <Section>
        <QRCode value={`${config.uploadUrl}/${userId}`} />
        <a href={`${config.uploadUrl}/${userId}`} target="_blank">
          {config.uploadUrl}/{userId}
        </a>
      </Section>
      <Section growthStage={growthStages.saen}>
        <Seed growthStage={growthStages.saen} debug={true}></Seed>
      </Section>
      <Section growthStage={growthStages.keimen}>
        <Seed growthStage={growthStages.keimen}></Seed>
      </Section>
      <Section growthStage={growthStages.verzweigen}>
        <Seed growthStage={growthStages.verzweigen}></Seed>
      </Section>
      <Section growthStage={growthStages.bluehen}>
        <Seed growthStage={growthStages.bluehen}></Seed>
      </Section>
      <Section growthStage={growthStages.kreuzen}>
        {/* <SeedPicker
          pickedSeed={selectedSeed}
          onSeedPicked={(seed) => {
            setSelectedSeed(seed);
          }}
        ></SeedPicker> */}
        <Seed growthStage={growthStages.kreuzen}></Seed>
      </Section>
      <Section growthStage={growthStages.welken}>
        <Seed growthStage={growthStages.welken}></Seed>
      </Section>
    </Container>
  );
}
