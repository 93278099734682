import React, { useState } from "react";
import styled from "styled-components";
import config from "../config.json";
import TextField from "@material-ui/core/TextField";

const Container = styled.div`
  margin: 0;
  background-color: white;
  color: black;
  width: 100vw;
  height: 100vh;
  padding: 50px;
  h1 {
    margin-top: 0;
    color: black;
  }
  ul {
    margin-top: 100px;
  }
`;

export default function () {
  const [userId, setUserId] = useState("");
  return (
    <Container>
      <h1>404</h1>
      <p>Did you get lost in the garden? It seems you took the wrong path.</p>
      <p>In case you remember, please enter your user id </p>
      <TextField
        value={userId}
        placeholder={"userId, e.g. GOTD"}
        onChange={(e) => {
          setUserId(e.target.value);
        }}
      ></TextField>
      <ul>
        <li>
          go to main page:{" "}
          <a href={`${config.url}/${userId ? userId : ""}`}>here</a>
        </li>
        {userId !== "" && (
          <li>
            go to upload page:{" "}
            <a href={`${config.uploadUrl}/${userId ? userId : ""}`}>here</a>
          </li>
        )}
      </ul>
    </Container>
  );
}
