import React, { useState } from "react";
import * as THREE from "three";
import { useLoader } from "react-three-fiber";

export default function Plant({ url, position, highlight }) {
  const texture = useLoader(THREE.TextureLoader, url);
  const [hover, setHover] = useState(false);
  const [active, setActive] = useState(false);
  let scale = [1, 1, 1];
  if (active) {
    scale = [10, 10, 10];
  } else if (hover) {
    scale = [3, 3, 3];
  }

  return (
    <mesh
      position={position}
      rotateZ={position[0] % 12}
      rotateY={position[1] % 6}
      onPointerOver={(e) => setHover(true)}
      onPointerOut={(e) => setHover(false)}
      onClick={(e) => setActive(!active)}
      scale={scale}
    >
      <planeBufferGeometry
        attach="geometry"
        args={[highlight ? 20 : 10, highlight ? 20 : 10]}
      />
      {/* <boxBufferGeometry args={[1, 1, 1]} attach="geometry" /> */}
      {/* <meshPhongMaterial color={"purple"} attach="material" /> */}
      <meshBasicMaterial attach="material" map={texture} />
    </mesh>
  );
}
